<script>
    import AppMainForm from './AppMainForm.vue';
    import AppMainHistory from './AppMainHistory.vue';

    export default {
        components: {
            AppMainForm,
            AppMainHistory
        }
    };
</script>

<template>
    <main class="App__Main">
        <AppMainForm></AppMainForm>
        <AppMainHistory></AppMainHistory>
    </main>
</template>

<style>
    .App__Main {
        padding: 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
</style>
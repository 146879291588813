/** @param {import('joi')} Joi */
module.exports = ({ Joi }) => {
    const
        createPath = Joi.object({
            id: Joi.string().alphanum().min(3)
        }),
        createParams = Joi.object({
            isDeletable: Joi.boolean(),
            webhookSecretType: Joi.string().valid('header', 'param'),
            webhookSecretKey: Joi.string().max(255),
            webhookSecretValue: Joi.string().max(255)
        })
        .and('webhookSecretType', 'webhookSecretKey', 'webhookSecretValue'),
        createBody = Joi.object({
            conditions: Joi.object({
                userAgent: Joi.string(),
                ip: Joi.string(),
                locale: Joi.string(),
                expiration: Joi.number().integer().min(Date.now())
            }).min(1),
            configs: Joi.array().items(Joi.link('#config')).min(1),
            isRandom: Joi.boolean(),
            url: Joi.string().uri({ scheme: 'https' }),
            params: Joi.array().items(Joi.object({
                index: Joi.number().integer().required(),
                key: Joi.string().required()
            })).min(1),
            iframe: Joi.object({
                title: Joi.string().required(),
                icon: Joi.string().uri({ scheme: 'https' }),
                isPwa: Joi.boolean()
            }),
            webhook: Joi.object({
                url: Joi.string().uri({ scheme: 'https' }).required(),
                body: Joi.array().items(Joi.string().valid('userAgent', 'ip', 'locale')).min(1)
            })
        })
        .xor('configs', 'url')
        .id('config'),
        createSchema = Joi.object({
            path: createPath,
            params: createParams,
            body: createBody
        })
        .and('params.webhookSecretType', 'body.webhook'),
        deleteSchema = Joi.object({
            path: Joi.object({
                id: Joi.string().alphanum().min(3)
            }),
            params: Joi.any(),
            body: Joi.object({
                message: Joi.string().max(255).required()
            })
        }),
        getVanitySchema = Joi.object({
            path: Joi.object({
                id: Joi.string().alphanum().min(3)
            }),
            params: Joi.any()
        });
    return {
        createSchema,
        deleteSchema,
        getVanitySchema
    };
};
<script>
    export default {
        props: {
            title: undefined,
            isFooterEnabled: {
                default: true
            },
            isSubmitButtonEnabled: {
                default: true
            },
            isBusy: undefined
        },
        methods: {
            close: function(){
                if(this.isBusy) return;
                this.$emit('close');
            },
            submit: function(){
                if(this.isBusy) return;
                this.$emit('submit');
            }
        }
    };
</script>

<template>
    <dialog
        open
        @keyup.esc="close"
        @keyup.enter="submit"
        @keydown.enter.prevent
    >
        <article>
            <header class="modal__header">
                {{ title }}
                <a
                    class="modal__header__close secondary"
                    href="#"
                    :aria-label="$t('modal__header__close')"
                    @click.prevent="close"
                ><i class="fa fa-times"></i></a>
            </header>
            <main>
                <slot></slot>
            </main>
            <footer
                v-if="isFooterEnabled"
                class="modal__footer"
            >
                <button
                    type="button"
                    role="button"
                    class="secondary"
                    :disabled="isBusy"
                    @click="close"
                >{{ $t('modal__footer__cancel') }}</button>
                <button
                    type="button"
                    role="button"
                    :disabled="!isSubmitButtonEnabled"
                    :aria-busy="isBusy"
                    @click="submit"
                >{{ $t('modal__footer__submit') }}</button>
            </footer>
        </article>
    </dialog>
</template>

<style>
    .modal__header,
    .modal__footer {
        display: flex;
    }
    .modal__header {
        column-gap: 1rem;
    }
    .modal__header__close {
        margin-left: auto;
    }
</style>
import { createApp } from 'vue';
import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import createShortener from '@cv.vg/client';

import App from './components/App.vue';
import i18n from './i18n.js';

import {
    defaultShortenerBaseUrl
} from '../config.js';

const app = createApp(App);

dayjs.extend(localizedFormat);

const store = createStore({
    state: () => ({
        locale: window.navigator.language.split('-')[0],
        isAutoCopyEnabled: true,
        isHistoryEnabled: true,
        shortenerBaseUrl: defaultShortenerBaseUrl,
        history: []
    }),
    mutations: {
        setSettings: (state, {
            locale,
            isAutoCopyEnabled,
            isHistoryEnabled,
            shortenerBaseUrl
        }) => {
            state.locale = locale;
            state.isAutoCopyEnabled = isAutoCopyEnabled;
            state.isHistoryEnabled = isHistoryEnabled;
            state.shortenerBaseUrl = shortenerBaseUrl;
        },
        unshiftHistory: (state, item) => {
            const
                existingIndex = state.history.findIndex(({ id }) => id === item.id),
                existingItem = state.history[existingIndex];
            if(existingIndex !== -1)
                state.history.splice(existingIndex, 1);
            state.history.unshift({ ...existingItem, ...JSON.parse(JSON.stringify(item)) });
        },
        setHistoryItemDeletionConfig: (state, {
            id,
            deletionTimestamp,
            deletionMessage
        }) => {
            const item = state.history.find(item => item.id === id);
            item.deletionTimestamp = deletionTimestamp;
            item.deletionMessage = deletionMessage;
        }
    },
    plugins: [new VuexPersistence({
        storage: window.localStorage,
        reducer: state => ({
            ...state,
            ...state.isHistoryEnabled ? {} : { history: undefined }
        })
    }).plugin]
});

app.use(store);

app.use(i18n(store.state.locale));

app.mixin({
    computed: {
        shortener: function(){
            return createShortener({ baseUrl: this.$store.state.shortenerBaseUrl });
        },
        dayjs: function(){
            dayjs.locale(this.$i18n.locale);
            return dayjs;
        }
    }
});

app.mount('.App');
<script>
    import modal from './_modal.vue';

    export default {
        components: {
            modal
        },
        props: {
            url: undefined,
            baseUrl: undefined,
            id: undefined,
            config: undefined,
            isEncrypted: undefined,
            isVanity: undefined,
            creationTimestamp: undefined,
            deletionTimestamp: undefined,
            deletionMessage: undefined,
            deletionCode: undefined
        },
        'data': () => ({
            isDeletionModalOpen: false,
            isDeletionMessageEnabled: false,
            inputDeletionMessage: undefined,
            isDeleting: false
        }),
        computed: {
            creationDate: function(){
                return this.dayjs(this.creationTimestamp);
            },
            deletionDate: function(){
                return this.deletionTimestamp ? this.dayjs(this.deletionTimestamp) : undefined;
            },
            isDeletable: function(){
                return this.deletionCode && !this.deletionTimestamp;
            },
            isDeletionConfigValid: function(){
                return !this.isDeletionMessageEnabled || !!this.inputDeletionMessage;
            }
        },
        methods: {
            deleteLink: async function(isConfirmed){
                if(!this.isDeletable) return;
                if(!isConfirmed)
                    await new Promise(resolve => setTimeout(resolve, 500));
                if(this.isDeleting || this.deletionTimestamp) return;
                this.isDeletionModalOpen = !isConfirmed;
                if(!isConfirmed)
                    return;
                this.isDeleting = true;
                const {
                    deletionTimestamp
                } = await this.shortener.delete(
                    this.id,
                    {
                        deletionCode: this.deletionCode,
                        message: this.inputDeletionMessage
                    }
                );
                this.$store.commit(
                    'setHistoryItemDeletionConfig',
                    {
                        id: this.id,
                        deletionTimestamp,
                        deletionMessage: this.isDeletionMessageEnabled ? this.inputDeletionMessage : undefined
                    }
                );
                this.isDeleting = false;
            },
            cancelDeletion: function(){
                this.isDeletionModalOpen = false;
            }
        }
    };
</script>

<template>
    <article class="App__Main__History__Item">
        <div class="App__Main__History__Item__Actions">
            <button
                v-if="isDeletable"
                :aria-busy="isDeleting"
                @click="event => deleteLink(event.detail === 2)"
            ><i
                v-if="!isDeleting"
                class="fa fa-trash"
            ></i></button>
        </div>
        <p class="App__Main__History__Item__Info">
            <i class="App__Main__History__Item__Info__Icon fa fa-link"></i>
            <a
                :href="url"
                target="_blank"
            ><span
                class="App__Main__History__Item__Info__BaseUrl"
            >{{ baseUrl }}/</span><span
                class="App__Main__History__Item__Info__Id"
            >{{ id }}</span></a>
            <template v-if="config.url">
                <i class="App__Main__History__Item__Info__Icon fa fa-arrow-right"></i>
                <a
                    class="App__Main__History__Item__Info__ConfigUrl"
                    :href="config.url"
                    target="_blank"
                    rel="nofollow"
                >{{ config.url }}</a>
            </template>
        </p>
        <p
            class="App__Main__History__Item__Info"
            v-if="isEncrypted && !deletionTimestamp"
        >
            <i class="App__Main__History__Item__Info__Icon fa fa-lock"></i>
            {{ $t('App__Main__History__Item__Encrypted') }}
        </p>
        <p
            class="App__Main__History__Item__Info"
            v-if="isVanity"
        >
            <i class="App__Main__History__Item__Info__Icon fa fa-pencil"></i>
            {{ $t('App__Main__History__Item__Vanity') }}
        </p>
        <p class="App__Main__History__Item__Info">
            <i class="App__Main__History__Item__Info__Icon fa fa-clock-o"></i>
            <time
                :datetime="creationDate.toISOString()"
            >{{ creationDate.format('L LT') }}</time>
        </p>
        <p
            class="App__Main__History__Item__Info"
            v-if="deletionTimestamp"
        >
            <i class="App__Main__History__Item__Info__Icon fa fa-trash"></i>
            <time
                :datetime="deletionDate.toISOString()"
                :data-tooltip="deletionMessage"
            >{{ deletionDate.format('L LT') }}</time>
        </p>
        <modal
            v-if="isDeletionModalOpen"
            :is-submit-button-enabled="isDeletionConfigValid"
            :title="$t('App__Main__History__Item__DeletionModal')"
            @close="cancelDeletion"
            @submit="deleteLink(true)"
        >
            <p>{{ $t('App__Main__History__Item__DeletionModal__Question') }} <a :href="url" target="_blank">{{ id }}</a> ?</p>
            <p>
                <label>
                    <input
                        type="checkbox"
                        v-model="isDeletionMessageEnabled"
                        role="switch"
                    >
                    {{ $t('App__Main__History__Item__DeletionModal__Message') }}
                </label>
                <template v-if="isDeletionMessageEnabled">
                    <textarea
                        v-model="inputDeletionMessage"
                        maxlength="255"
                    ></textarea>
                    <span v-if="isEncrypted">
                        <i class="fa fa-info-circle"></i>
                        {{ $t('App__Main__History__Item__DeletionModal__Message__Warning') }}
                    </span>
                </template>
            </p>
            <p v-if="!isDeletionMessageEnabled">
                <i class="fa fa-lightbulb-o"></i>
                {{ $t('App__Main__History__Item__DeletionModal__Tip') }}
            </p>
        </modal>
    </article>
</template>

<style>
    .App__Main__History__Item__Actions {
        float: right;
        padding-left: 0.5rem;
    }
    .App__Main__History__Item__Info {
        margin: 0;
    }
    .App__Main__History__Item__Info__Icon:not(:first-child) {
        margin-left: 0.5rem;
    }
    .App__Main__History__Item__Info__Icon {
        margin-right: 0.5rem;
    }
    .App__Main__History__Item__Info {
        display: flex;
        align-items: center;
    }
    .App__Main__History__Item__Info__BaseUrl {
        opacity: 0.5;
    }
    .App__Main__History__Item__Info__ConfigUrl {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
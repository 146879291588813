<script>
    export default {
        methods: {
            openSettings: function(){
                this.$emit('open-settings');
            }
        }
    };
</script>

<template>
    <nav class="App__Nav">
        <ul><li><h1
            class="App__Nav__Title"
        >cv.vg</h1></li></ul>
        <ul>
            <li><a
                href="#"
                @click.prevent="openSettings"
                :aria-label="$t('App__Nav__Settings')"
            ><i
                class="fa fa-cog"
            ></i></a></li>
            <li><a
                href="https://git.kaki87.net/cv.vg/web"
                target="_blank"
                :aria-label="$t('App__Nav__About')"
            ><i
                class="fa fa-info"
            ></i></a></li>
        </ul>
    </nav>
</template>

<style>
    .App__Nav {
        padding: 0 1rem;
    }
</style>
import { createI18n } from 'vue-i18n';

export default locale => createI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
        'en': {
            'modal__header__close': 'Close',
            'modal__footer__cancel': 'Cancel',
            'modal__footer__submit': 'Submit',
            'App__Footer__Count': '{count} links shortened',
            'App__Footer__Count--tooltip': 'As of {date}',
            'App__Main__FormContainer__Form__Advanced': 'Advanced shortening configuration',
            'App__Main__FormContainer__Form__Advanced__ExpirationModal': 'Expiration options',
            'App__Main__FormContainer__Form__Advanced__ExpirationModal__Warning': 'The redirect URL will remain available although the short URL will no longer redirect to it.',
            'App__Main__FormContainer__Form__Advanced__VanityModal': 'Vanity options',
            'App__Main__FormContainer__Form__Submit': 'Shorten',
            'App__Main__FormContainer__Form__Reset': 'Reset',
            'App__Main__Form__Password': 'Password',
            'App__Main__Form__Password__Pwned': 'This password has been breached',
            'App__Main__Form__Password__Pwned__More': 'More info',
            'App__Main__History__Item__Encrypted': 'Encrypted',
            'App__Main__History__Item__Vanity': 'Vanity',
            'App__Main__History__Item__DeletionModal': 'Confirm deletion ?',
            'App__Main__History__Item__DeletionModal__Question': 'Are you sure about deleting',
            'App__Main__History__Item__DeletionModal__Message': 'Attach message',
            'App__Main__History__Item__DeletionModal__Message__Warning': 'The attached message will not be encrypted.',
            'App__Main__History__Item__DeletionModal__Tip': 'Double-click the deletion button to avoid this prompt.',
            'App__Nav__Settings': 'Settings',
            'App__Nav__About': 'About',
            'App__Settings': 'Settings',
            'App__Settings__Locale': 'Language',
            'App__Settings__Locale__Item': {
                'en': 'English',
                'fr': 'French'
            },
            'App__Settings__AutoCopy': 'Auto-copy shortened URL',
            'App__Settings__History': 'Enable history',
            'App__Settings__Advanced': 'Advanced settings',
            'App__Settings__BaseUrl': 'Shorten API',
            'App__Settings__DisablingHistory': 'By disabling history, you lose the ability to delete past and future generated links.',
            'App__Url': 'URL info'
        },
        'fr': {
            'modal__header__close': 'Fermer',
            'modal__footer__cancel': 'Annuler',
            'modal__footer__submit': 'Valider',
            'App__Footer__Count': '{count} liens raccourcis',
            'App__Footer__Count--tooltip': 'Au {date}',
            'App__Main__FormContainer__Form__Advanced': 'Configuration de raccourcissement avancée',
            'App__Main__FormContainer__Form__Advanced__ExpirationModal': 'Options d\'expiration',
            'App__Main__FormContainer__Form__Advanced__ExpirationModal__Warning': 'L\'URL de redirection restera disponible bien que l\'URL courte ne redirigera plus dessus.',
            'App__Main__FormContainer__Form__Advanced__VanityModal': 'Options Vanity',
            'App__Main__FormContainer__Form__Submit': 'Raccourcir',
            'App__Main__FormContainer__Form__Reset': 'Réinitialiser',
            'App__Main__Form__Password': 'Mot de passe',
            'App__Main__Form__Password__Pwned': 'Ce mot de passe est compromis',
            'App__Main__Form__Password__Pwned__More': 'Plus d\'infos',
            'App__Main__History__Item__Encrypted': 'Chiffré',
            'App__Main__History__Item__Vanity': 'Personnalisé',
            'App__Main__History__Item__DeletionModal': 'Confirmer la suppression ?',
            'App__Main__History__Item__DeletionModal__Question': 'Voulez-vous vraiment supprimer',
            'App__Main__History__Item__DeletionModal__Message': 'Joindre un message',
            'App__Main__History__Item__DeletionModal__Message__Warning': 'Le message joint ne sera pas chiffré.',
            'App__Main__History__Item__DeletionModal__Tip': 'Double-cliquez le bouton de suppression pour ne pas avoir à confirmer.',
            'App__Nav__Settings': 'Paramètres',
            'App__Nav__About': 'A propos',
            'App__Settings': 'Paramètres',
            'App__Settings__Locale': 'Langue',
            'App__Settings__Locale__Item': {
                'en': 'Anglais',
                'fr': 'Français'
            },
            'App__Settings__AutoCopy': 'Copier automatiquement l\'URL raccourcie',
            'App__Settings__History': 'Activer l\'historique',
            'App__Settings__Advanced': 'Paramètres avancés',
            'App__Settings__BaseUrl': 'API raccourcisseur',
            'App__Settings__DisablingHistory': 'En désactivant l\'historique, vous perdez la possibilité de supprimer les liens générés passés et futurs.',
            'App__Url': 'Informations sur l\'URL'
        }
    }
});
<script>
    import AppMainHistoryItem from './AppMainHistoryItem.vue';

    export default {
        components: {
            AppMainHistoryItem
        },
        computed: {
            history: function(){
                return this.$store.state.history;
            }
        }
    };
</script>

<template>
    <section class="App__Main__History">
        <AppMainHistoryItem
            v-for="item in history"
            v-bind="item"
            :key="item.id"
        ></AppMainHistoryItem>
    </section>
</template>

<style>
    .App__Main__History {
        flex: 1 1 auto;
        height: 0;
        overflow: auto;
        margin-bottom: 0;
        padding-right: 1rem;
    }
</style>
<script>
    import modal from './_modal.vue';

    export default {
        components: {
            modal
        },
        props: {
            id: undefined
        }
    };
</script>

<template>
    <modal
        :title="$t('App__Url')"
        :is-footer-enabled="false"
    >
        ID : <code>{{ id }}</code>
    </modal>
</template>
<script>
    import AppNav from './AppNav.vue';
    import AppSettings from './AppSettings.vue';
    import AppMain from './AppMain.vue';
    import AppFooter from './AppFooter.vue';
    import AppUrl from './AppUrl.vue';

    export default {
        components: {
            AppNav,
            AppSettings,
            AppMain,
            AppFooter,
            AppUrl
        },
        'data': () => ({
            isSettingsOpen: false
        }),
        computed: {
            urlId: () => new URL(window.location.href).searchParams.get('id')
        },
        methods: {
            openSettings: function(){
                this.isSettingsOpen = true;
            },
            closeSettings: function(){
                this.isSettingsOpen = false;
            },
            closeUrl: () => {
                const url = new URL(window.location.href);
                url.searchParams.delete('id');
                window.location.assign(url.toString());
            }
        }
    };
</script>

<template class="App">
    <AppNav
        @open-settings="openSettings"
    ></AppNav>
    <AppSettings
        v-if="isSettingsOpen"
        @close="closeSettings"
    ></AppSettings>
    <AppMain></AppMain>
    <AppFooter></AppFooter>
    <AppUrl
        v-if="urlId"
        :id="urlId"
        @close="closeUrl"
    ></AppUrl>
</template>

<style>
    @import '../../node_modules/@picocss/pico/css/pico.min.css';
    @import '../../node_modules/fork-awesome/css/fork-awesome.min.css';

    .App {
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 750px;
    }
</style>
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var UnexpectedHttpResponseError = /** @class */ (function (_super) {
    __extends(UnexpectedHttpResponseError, _super);
    function UnexpectedHttpResponseError(responseText) {
        var _this = _super.call(this, "IsPwned received an unexpected HTTP response: " + responseText) || this;
        _this.name = 'UnexpectedHttpResponseError';
        return _this;
    }
    return UnexpectedHttpResponseError;
}(Error));
exports.UnexpectedHttpResponseError = UnexpectedHttpResponseError;
var TimedOutError = /** @class */ (function (_super) {
    __extends(TimedOutError, _super);
    function TimedOutError() {
        var _this = _super.call(this, 'IsPwned timed out while making request.') || this;
        _this.name = 'TimedOutError';
        return _this;
    }
    return TimedOutError;
}(Error));
exports.TimedOutError = TimedOutError;
var InvalidPasswordError = /** @class */ (function (_super) {
    __extends(InvalidPasswordError, _super);
    function InvalidPasswordError() {
        var _this = _super.call(this, 'IsPwned received an invalid or empty password.') || this;
        _this.name = 'InvalidPasswordError';
        return _this;
    }
    return InvalidPasswordError;
}(Error));
exports.InvalidPasswordError = InvalidPasswordError;
var BreachedError = /** @class */ (function (_super) {
    __extends(BreachedError, _super);
    function BreachedError(count) {
        var _this = _super.call(this, 'This password has been detected in a known breach.') || this;
        _this.name = 'BreachedError';
        _this.count = count;
        return _this;
    }
    return BreachedError;
}(Error));
exports.BreachedError = BreachedError;
var rotateLeft = function (n, s) { return (n << s) | (n >>> (32 - s)); };
var cvtHex = function (v) {
    var result = '';
    for (var i = 7; i >= 0; i--) {
        result += ((v >>> (i * 4)) & 0x0f).toString(16);
    }
    return result;
};
var utf8Encode = function (str) {
    var result = '';
    str = str.replace(/\r\n/g, '\n');
    for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        if (char < 128) {
            result += String.fromCharCode(char);
        }
        else if (char > 127 && char < 2048) {
            result += String.fromCharCode((char >> 6) | 192);
            result += String.fromCharCode((char & 63) | 128);
        }
        else {
            result += String.fromCharCode((char >> 12) | 224);
            result += String.fromCharCode(((char >> 6) & 63) | 128);
            result += String.fromCharCode((char & 63) | 128);
        }
    }
    return result;
};
var sha1 = function (input) {
    var W = new Array(80);
    var H0 = 0x67452301;
    var H1 = 0xefcdab89;
    var H2 = 0x98badcfe;
    var H3 = 0x10325476;
    var H4 = 0xc3d2e1f0;
    var str = utf8Encode(input);
    var wordArray = [];
    for (var i = 0; i < str.length - 3; i += 4) {
        wordArray.push((str.charCodeAt(i) << 24) |
            (str.charCodeAt(i + 1) << 16) |
            (str.charCodeAt(i + 2) << 8) |
            str.charCodeAt(i + 3));
    }
    wordArray.push((function () {
        switch (str.length % 4) {
            case 0:
                return 0x080000000;
            case 1:
                return (str.charCodeAt(str.length - 1) << 24) | 0x0800000;
            case 2:
                return ((str.charCodeAt(str.length - 2) << 24) |
                    (str.charCodeAt(str.length - 1) << 16) |
                    0x08000);
            case 3:
                return ((str.charCodeAt(str.length - 3) << 24) |
                    (str.charCodeAt(str.length - 2) << 16) |
                    (str.charCodeAt(str.length - 1) << 8) |
                    0x80);
        }
    })());
    while (wordArray.length % 16 !== 14) {
        wordArray.push(0);
    }
    wordArray.push(str.length >>> 29);
    wordArray.push((str.length << 3) & 0x0ffffffff);
    var A, B, C, D, E, iii;
    for (var i = 0; i < wordArray.length; i += 16) {
        // First round
        for (var ii = 0; ii < 16; ii++) {
            W[ii] = wordArray[i + ii];
        }
        // Second Round
        for (var ii = 16; ii <= 79; ii++) {
            W[ii] = rotateLeft(W[ii - 3] ^ W[ii - 8] ^ W[ii - 14] ^ W[ii - 16], 1);
        }
        A = H0;
        B = H1;
        C = H2;
        D = H3;
        E = H4;
        // Encoding Round 1
        for (var ii = 0; ii <= 19; ii++) {
            iii =
                (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[ii] + 0x5a827999) &
                    0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = iii;
        }
        // Encoding Round 2
        for (var ii = 20; ii <= 39; ii++) {
            iii =
                (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[ii] + 0x6ed9eba1) & 0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = iii;
        }
        // Encoding Round 3
        for (var ii = 40; ii <= 59; ii++) {
            iii =
                (rotateLeft(A, 5) +
                    ((B & C) | (B & D) | (C & D)) +
                    E +
                    W[ii] +
                    0x8f1bbcdc) &
                    0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = iii;
        }
        // Encoding Round 4
        for (var ii = 60; ii <= 79; ii++) {
            iii =
                (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[ii] + 0xca62c1d6) & 0x0ffffffff;
            E = D;
            D = C;
            C = rotateLeft(B, 30);
            B = A;
            A = iii;
        }
        // Chars
        H0 = (H0 + A) & 0x0ffffffff;
        H1 = (H1 + B) & 0x0ffffffff;
        H2 = (H2 + C) & 0x0ffffffff;
        H3 = (H3 + D) & 0x0ffffffff;
        H4 = (H4 + E) & 0x0ffffffff;
    }
    return (cvtHex(H0) +
        cvtHex(H1) +
        cvtHex(H2) +
        cvtHex(H3) +
        cvtHex(H4)).toUpperCase();
};
var IsPwned = /** @class */ (function () {
    function IsPwned(config) {
        if (config === void 0) { config = {}; }
        if (typeof window === 'undefined') {
            throw new Error('IsPwned is meant for use in the browser only.');
        }
        this.endpoint = config.endpoint || 'https://api.pwnedpasswords.com/range/';
        this.userAgent = config.userAgent || 'is-pwned-js';
        this.timeout = config.timeout || 5000;
        this.resolveOnTimeout = config.resolveOnTimeout || false;
    }
    IsPwned.prototype.hashPassword = function (password) {
        if (!password || typeof password !== 'string') {
            throw new InvalidPasswordError();
        }
        return sha1(password);
    };
    IsPwned.prototype.check = function (password) {
        return __awaiter(this, void 0, void 0, function () {
            var hashedPassword, firstFiveOfPassword, remainderOfPassword, breaches;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hashedPassword = this.hashPassword(password);
                        firstFiveOfPassword = hashedPassword.substr(0, 5);
                        remainderOfPassword = hashedPassword.substr(5);
                        return [4 /*yield*/, this.makeRequest(firstFiveOfPassword)];
                    case 1:
                        breaches = (_a.sent())
                            .split('\n')
                            .filter(function (hs) { return hs.indexOf(remainderOfPassword) > -1; });
                        if (breaches.length > 0) {
                            throw new BreachedError(breaches.map(function (v) { return parseInt(v.split(':')[1]); }).reduce(function (v, t) { return v + t; }));
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    IsPwned.prototype.makeRequest = function (firstFiveOfHash) {
        return __awaiter(this, void 0, void 0, function () {
            var abortController, timer, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        abortController = typeof AbortController !== 'undefined'
                            ? new AbortController()
                            : undefined;
                        timer = setTimeout(function () {
                            if (abortController) {
                                abortController.abort();
                            }
                            if (_this.resolveOnTimeout) {
                                return Promise.resolve();
                            }
                            throw new TimedOutError();
                        }, this.timeout);
                        return [4 /*yield*/, fetch(this.endpoint + firstFiveOfHash, {
                                signal: abortController ? abortController.signal : undefined,
                                headers: {
                                    'User-Agent': this.userAgent
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        clearTimeout(timer);
                        if (response.status === 200) {
                            return [2 /*return*/, response.text()];
                        }
                        throw new UnexpectedHttpResponseError(response.statusText);
                }
            });
        });
    };
    return IsPwned;
}());
exports.default = IsPwned;

<script>
    import IsPwned from 'is-pwned';

    import modal from './_modal.vue';

    export default {
        components: {
            modal
        },
        'data': () => ({
            password: undefined,
            passwordTimeout: undefined,
            isPwned: false
        }),
        methods: {
            submit: function(){
                if(!this.password) return;
                this.$emit('submit2', this.password);
            }
        },
        watch: {
            'password': function(){
                window.clearTimeout(this.passwordTimeout);
                this.passwordTimeout = window.setTimeout(async () => {
                    try {
                        await new IsPwned().check(this.password);
                        this.isPwned = false;
                    }
                    catch(error){
                        this.isPwned = error.name === 'BreachedError';
                    }
                }, 500);
            }
        },
        'mounted': function(){
            this.$refs['App__Main__Form__Password__Input'].focus();
        },
        'beforeUnmount': function(){
            window.clearTimeout(this.passwordTimeout);
        }
    };
</script>

<template>
    <modal
        :title="$t('App__Main__Form__Password')"
        :is-submit-button-enabled="!!password"
        @submit="submit"
    >
        <label><input
            ref="App__Main__Form__Password__Input"
            type="password"
            v-model="password"
        ></label>
        <p
            v-if="isPwned"
            class="App__Main__Form__Password__Pwned"
        >
            <i class="fa fa-warning"></i>
            {{ $t('App__Main__Form__Password__Pwned') }}.
            <a
                href="https://haveibeenpwned.com/passwords"
                target="_blank"
            >{{ $t('App__Main__Form__Password__Pwned__More') }}</a>
        </p>
    </modal>
</template>
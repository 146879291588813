<script>
    import Joi from 'joi';
    import getSchemas from '@cv.vg/schemas';

    import AppMainFormAdvanced from './AppMainFormAdvanced.vue';
    import AppMainFormPassword from './AppMainFormPassword.vue';

    const
        { createSchema } = getSchemas({ Joi }),
        getInitialConfig = () => JSON.parse(JSON.stringify({
            conditions: undefined,
            url: undefined
        })),
        getInitialParams = () => JSON.parse(JSON.stringify({
            vanityId: undefined,
            vanityCode: undefined,
            isDeletable: false,
            password: undefined
        }));

    export default {
        components: {
            AppMainFormAdvanced,
            AppMainFormPassword
        },
        'data': () => ({
            config: getInitialConfig(),
            params: getInitialParams(),
            isPasswordModalOpen: false,
            isBusy: false
        }),
        computed: {
            isPasswordEnabled: function(){
                return !!this.params.password;
            },
            isConfigValid: function(){
                return !createSchema.validate({
                    body: this.config,
                    params: JSON.parse(JSON.stringify({
                        ...this.params,
                        vanityId: undefined,
                        vanityCode: undefined,
                        password: undefined
                    }))
                }).error;
            },
            shortenerBaseUrl: function(){
                return this.$store.state.shortenerBaseUrl;
            },
            isHistoryEnabled: function(){
                return this.$store.state.isHistoryEnabled;
            }
        },
        methods: {
            togglePassword: async function(password){
                if(this.params.password)
                    this.params.password = undefined;
                else {
                    if(password)
                        this.params.password = password;
                    this.isPasswordModalOpen = !this.isPasswordModalOpen;
                }
            },
            reset: function(){
                this.config = getInitialConfig();
                this.params = getInitialParams();
            },
            shorten: async function(){
                if(
                    this.isBusy
                    ||
                    !this.isConfigValid
                ) return;
                this.isBusy = true;
                const
                    {
                        id,
                        creationTimestamp,
                        deletionCode
                    } = await this.shortener.shorten(
                        this.config,
                        this.params
                    ),
                    baseUrl = this.shortenerBaseUrl,
                    url = `${baseUrl}/${id}`;
                this.$store.commit('unshiftHistory', {
                    id,
                    creationTimestamp,
                    deletionCode,
                    baseUrl,
                    url,
                    config: this.config,
                    isEncrypted: !!this.params.password,
                    isVanity: !!this.params.vanityId
                });
                if(this.$store.state.isAutoCopyEnabled && document.hasFocus())
                    await window.navigator.clipboard.writeText(url);
                this.reset();
                this.isBusy = false;
            }
        },
        watch: {
            isHistoryEnabled: function(isHistoryEnabled){
                if(!isHistoryEnabled)
                    this.params.isDeletable = false;
            }
        }
    };
</script>

<template>
    <form class="App__Main__FormContainer">
        <fieldset
            class="App__Main__FormContainer__Form"
            :disabled="isBusy"
        >
            <label
                style="grid-area: App__Main__FormContainer__Form__Url"
                aria-label="URL"
            ><input
                type="url"
                placeholder="https://example.com"
                v-model="config.url"
                autofocus
            ></label>
            <div
                class="App__Main__FormContainer__Form__Switches"
                style="grid-area: App__Main__FormContainer__Form__Switches"
            >
                <label
                    class="App__Main__FormContainer__Form__Switches__Switch"
                    data-tooltip="Encrypted"
                    data-placement="left"
                ><input
                    type="checkbox"
                    role="switch"
                    :checked="isPasswordEnabled"
                    :key="isPasswordEnabled"
                    @click.prevent="togglePassword()"
                ><i class="fa fa-lock"></i></label>
                <label
                    class="App__Main__FormContainer__Form__Switches__Switch"
                    data-tooltip="Deletable"
                    data-placement="left"
                ><input
                    type="checkbox"
                    role="switch"
                    v-model="params.isDeletable"
                    :disabled="!isHistoryEnabled"
                ><i class="fa fa-trash"></i></label>
            </div>
            <details
                style="grid-area: App__Main__FormContainer__Form__Advanced"
            >
                <summary>{{ $t('App__Main__FormContainer__Form__Advanced') }}</summary>
                <AppMainFormAdvanced
                    :config="config"
                    :params="params"
                ></AppMainFormAdvanced>
            </details>
            <button
                style="grid-area: App__Main__FormContainer__Form__Submit"
                @click.prevent="shorten"
                :aria-busy="isBusy"
                :disabled="!isConfigValid"
            >{{ $t('App__Main__FormContainer__Form__Submit') }}</button>
            <button
                class="secondary"
                style="grid-area: App__Main__FormContainer__Form__Reset"
                @click="reset"
                :disabled="isBusy"
                type="button"
                :data-tooltip="$t('App__Main__FormContainer__Form__Reset')"
            ><i class="fa fa-undo"></i></button>
        </fieldset>
        <AppMainFormPassword
            v-if="isPasswordModalOpen"
            @close="togglePassword"
            @submit2="togglePassword"
        ></AppMainFormPassword>
    </form>
</template>

<style>
    .App__Main__FormContainer__Form {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        column-gap: 0.5rem;
        grid-template-areas:
            'App__Main__FormContainer__Form__Url    App__Main__FormContainer__Form__Switches'
            'App__Main__FormContainer__Form__Advanced App__Main__FormContainer__Form__Advanced'
            'App__Main__FormContainer__Form__Submit App__Main__FormContainer__Form__Reset'
    }
    .App__Main__FormContainer__Form__Switches__Switch {
        border-bottom: none !important;
    }
</style>
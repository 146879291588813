<script>
    import Joi from 'joi';
    import getSchemas from '@cv.vg/schemas';

    import modal from './_modal.vue';

    const {
        createSchema,
        getVanitySchema
    } = getSchemas({ Joi });

    export default {
        components: {
            modal
        },
        props: {
            config: undefined,
            params: undefined
        },
        'data': () => ({
            inputExpiration: undefined,
            isExpirationModalOpen: undefined,
            inputVanityId: undefined,
            inputVanityCode: undefined,
            isVanityModalOpen: false,
            isVanityModalBusy: false
        }),
        computed: {
            inputExpirationTimestamp: function(){
                return this.inputExpiration ? this.dayjs(this.inputExpiration).valueOf() : undefined;
            },
            isExpiration: function(){
                return !!this.config.conditions?.expiration;
            },
            isExpirationValid: function(){
                return !!this.inputExpirationTimestamp && !createSchema.validate({
                    body: {
                        url: 'https://example.com',
                        conditions: {
                            expiration: this.inputExpirationTimestamp
                        }
                    }
                }).error;
            },
            isVanity: function(){
                return !!this.params.vanityId && !!this.params.vanityCode;
            },
            isVanityValid: function(){
                return (
                    !getVanitySchema.validate({
                        path: {
                            id: this.inputVanityId
                        }
                    }).error
                    &&
                    !!this.inputVanityCode
                );
            }
        },
        methods: {
            toggleExpiration: function(expiration){
                if(this.config.conditions?.expiration)
                    this.config.conditions = undefined;
                else {
                    if(expiration)
                        this.config.conditions = { expiration };
                    this.isExpirationModalOpen = !this.isExpirationModalOpen;
                }
            },
            toggleVanity: async function({ id, code } = {}){
                if(this.isVanityModalBusy) return;
                if(this.params.vanityId && this.params.vanityCode){
                    this.params.vanityId = undefined;
                    this.params.vanityCode = undefined;
                }
                else {
                    if(id && code){
                        this.isVanityModalBusy = true;
                        try {
                            await this.shortener.getVanity({ id, code });
                            this.params.vanityId = id;
                            this.params.vanityCode = code;
                            this.isVanityModalOpen = false;
                        }
                        catch(error){
                            // TODO
                        }
                        this.isVanityModalBusy = false;
                    }
                    else
                        this.isVanityModalOpen = !this.isVanityModalOpen;
                }
            }
        }
    };
</script>

<template>
    <p>
        <label><input
            type="checkbox"
            role="switch"
            :checked="isExpiration"
            :key="isExpiration"
            @click.prevent="toggleExpiration()"
        >Expiration</label>
        <label><input
            type="checkbox"
            role="switch"
            :checked="isVanity"
            :key="isVanity"
            @click.prevent="toggleVanity()"
        >Vanity</label>
    </p>
    <modal
        v-if="isExpirationModalOpen"
        :title="$t('App__Main__FormContainer__Form__Advanced__ExpirationModal')"
        :is-submit-button-enabled="isExpirationValid"
        @close="toggleExpiration"
        @submit="toggleExpiration(inputExpirationTimestamp)"
    >
        <label>
            Date
            <input
                type="datetime-local"
                v-model="inputExpiration"
            >
        </label>
        <span>
            <i class="fa fa-info-circle"></i>
            {{ $t('App__Main__FormContainer__Form__Advanced__ExpirationModal__Warning') }}
        </span>
    </modal>
    <modal
        v-if="isVanityModalOpen"
        :title="$t('App__Main__FormContainer__Form__Advanced__VanityModal')"
        :is-submit-button-enabled="isVanityValid"
        :is-busy="isVanityModalBusy"
        @close="toggleVanity"
        @submit="toggleVanity({ id: inputVanityId, code: inputVanityCode })"
    >
        <label>
            ID
            <input
                type="text"
                v-model="inputVanityId"
            >
        </label>
        <label>
            Code
            <input
                type="password"
                v-model="inputVanityCode"
            >
        </label>
    </modal>
</template>
<script>
    export default {
        'data': () => ({
            count: undefined,
            lastRefreshTimestamp: undefined,
            refreshInterval: undefined
        }),
        methods: {
            refresh: async function(){
                this.count = await this.shortener.getCount();
                this.lastRefreshTimestamp = Date.now();
            }
        },
        'mounted': async function(){
            await this.refresh();
            this.refreshInterval = window.setInterval(async () => {
                if(document.hasFocus())
                    await this.refresh();
            }, 60000);
        },
        'beforeUnmount': function(){
            window.clearInterval(this.refreshInterval);
        }
    };
</script>

<template>
    <footer class="App__Footer">
        <span
            :data-tooltip="$t('App__Footer__Count--tooltip', { date: dayjs(lastRefreshTimestamp).format('L LT') })"
        >{{ $t('App__Footer__Count', { count }) }}</span>
    </footer>
</template>

<style>
    .App__Footer {
        padding: 0 0 0.5rem 0;
        text-align: center;
    }
</style>
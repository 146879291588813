<script>
    import modal from './_modal.vue';

    export default {
        components: {
            modal
        },
        'data': function(){
            return {
                locale: this.$store.state.locale,
                isAutoCopyEnabled: this.$store.state.isAutoCopyEnabled,
                isHistoryEnabled: this.$store.state.isHistoryEnabled,
                shortenerBaseUrl: this.$store.state.shortenerBaseUrl
            };
        },
        computed: {
            isShortenerBaseUrlValid: function(){
                try {
                    const url = new URL(this.shortenerBaseUrl);
                    return url.origin === this.shortenerBaseUrl;
                }
                catch {
                    return false;
                }
            },
            isSettingsValid: function(){
                return [
                    this.isShortenerBaseUrlValid
                ].every(item => item === true);
            },
            availableLocales: function(){
                return this.$i18n.availableLocales;
            },
            isDisablingHistory: function(){
                return this.$store.state.isHistoryEnabled && !this.isHistoryEnabled;
            }
        },
        methods: {
            close: function(){
                this.$emit('close');
            },
            submit: function(){
                if(!this.isSettingsValid) return;
                const isChangingLocale = this.$store.state.locale !== this.locale;
                this.$emit('close');
                this.$store.commit('setSettings', {
                    locale: this.locale,
                    isAutoCopyEnabled: this.isAutoCopyEnabled,
                    isHistoryEnabled: this.isHistoryEnabled,
                    shortenerBaseUrl: this.shortenerBaseUrl
                });
                if(isChangingLocale)
                    this.$i18n.locale = this.locale;
            }
        }
    };
</script>

<template>
    <modal
        :title="$t('App__Settings')"
        :is-submit-button-enabled="isSettingsValid"
        @close="close"
        @submit="submit"
    >
        <label>
            {{ $t('App__Settings__Locale') }}
            <select
                v-model="locale"
            ><option
                v-for="locale in availableLocales"
                :value="locale"
                :selected="locale"
            >{{ $t(`App__Settings__Locale__Item.${locale}`) }}</option></select>
        </label>
        <label><input
            type="checkbox"
            role="switch"
            v-model="isAutoCopyEnabled"
        >{{ $t('App__Settings__AutoCopy') }}</label>
        <label><input
            type="checkbox"
            role="switch"
            v-model="isHistoryEnabled"
        >{{ $t('App__Settings__History') }}</label>
        <br>
        <details>
            <summary>{{ $t('App__Settings__Advanced') }}</summary>
            <label>
                {{ $t('App__Settings__BaseUrl') }}
                <input
                    type="url"
                    placeholder="https://cv.vg"
                    v-model="shortenerBaseUrl"
                >
            </label>
        </details>
        <p v-if="isDisablingHistory">
            <i class="fa fa-warning"></i>
            {{ $t('App__Settings__DisablingHistory') }}
        </p>
    </modal>
</template>
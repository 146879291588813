const axios = require('axios');

/**
 * @param {String} baseUrl
 */
module.exports = ({ baseUrl }) => {
    const client = axios.create({ baseURL: baseUrl });
    return {
        /**
         * @typedef {Object} shortenConfigConditions
         * @property {String} [userAgent]
         * @property {String} [ip]
         * @property {String} [locale]
         * @property {Number} [expiration]
         */
        /**
         * @typedef {Object} shortenConfigIframe
         * @property {String} title
         * @property {String} [icon]
         * @property {Boolean} [isPwa]
         */
        /**
         * @typedef {Object} shortenConfigWebhook
         * @property {String} url
         * @property {('userAgent'|'ip'|'locale')[]} [body]
         */
        /**
         * @typedef {Object} shortenConfig
         * @property {shortenConfigConditions} [conditions]
         * @property {shortenConfig[]} [configs]
         * @property {Boolean} [isRandom]
         * @property {String} [url]
         * @property {{index: Number, key: String}[]} [params]
         * @property {shortenConfigIframe} [iframe]
         * @property {shortenConfigWebhook} [webhook]
         */
        /**
         * @typedef {Object} shortenParams
         * @property {String} [vanityId]
         * @property {String} [vanityCode]
         * @property {Boolean} [isDeletable]
         * @property {String} [password]
         * @property {('header'|'param')} [webhookSecretType]
         * @property {String} [webhookSecretKey]
         * @property {String} [webhookSecretValue]
         */
        /**
         * @param {shortenConfig} shortenConfig
         * @param {shortenParams} shortenParams
         * @returns {Promise<{id: String, creationTimestamp: Number, deletionCode: String}>}
         */
        shorten: async (shortenConfig, shortenParams = {}) => (await client(
            {
                url: `/${shortenParams.vanityId || ''}`,
                method: shortenParams.vanityId ? 'PUT' : 'POST',
                params: {
                    ...shortenParams,
                    vanityId: undefined,
                    vanityCode: undefined,
                    password: undefined
                },
                ...(shortenParams.vanityCode || shortenParams.password) ? {
                    auth: {
                        username: shortenParams.vanityCode || '',
                        password: shortenParams.password || ''
                    }
                } : {},
                data: shortenConfig
            }
        )).data,
        /**
         * @typedef {Object} unshortenResponse
         * @property {Number} creationTimestamp
         * @property {shortenConfig} config
         * @property {('userAgent'|'ip'|'locale'|'expiration'|'any')} [unmetCondition]
         * @property {String} [url]
         * @property {Number} [randomConfigIndex]
         * @property {Number} [conditionalConfigIndex]
         * @property {Number} [webhookResponseTimeMs]
         */
        /**
         *
         * @param {String} id
         * @param {Object} [params]
         * @param {String} [password]
         * @param {Boolean} [isTracked]
         * @returns {Promise<unshortenResponse|{html: String}>}
         */
        get: async (id, { params, password, isTracked } = {}) => {
            const {
                status,
                data
            } = await client(
                `${id}${isTracked ? '/t' : ''}`,
                {
                    maxRedirects: 0,
                    validateStatus: status => [200, 302].includes(status),
                    params,
                    ...password ? {
                        auth: {
                            username: '',
                            password
                        }
                    } : {}
                }
            );
            return {
                200: { html: data },
                302: data
            }[status];
        },
        /**
         * @typedef {Object} shortenedUrl
         * @property {Number} creationTimestamp
         * @property {Number} [editionTimestamp]
         * @property {shortenConfig} [config]
         * @property {Boolean} isEncrypted
         * @property {Number} [deletionTimestamp]
         * @property {String} [deletionMessage]
         */
        /**
         *
         * @param {String} id
         * @param {String} [password]
         * @returns {Promise<shortenedUrl>}
         */
        getInfo: async (id, { password } = {}) => (await client(
            `${id}/info`,
            password ? { auth: { username: '', password } } : {}
        )).data,
        /** @returns {Promise<String[]>} */
        getList: async () => (await client('?list')).data,
        /** @returns {Promise<Number>} */
        getCount: async () => (await client('?count')).data,
        /**
         * @param {String} id
         * @param {String} deletionCode
         * @param {String} [message]
         * @returns {Promise<{deletionTimestamp: Number}>}
         */
        delete: async (id, { deletionCode, message }) => (await client.delete(
            `/${id}`,
            {
                auth: {
                    username: '',
                    password: deletionCode
                },
                ...message ? {
                    data: {
                        message
                    }
                } : {}
            }
        )).data,
        /**
         *
         * @param {String} id
         * @param {String} code
         * @returns {Promise<import('axios').AxiosResponse>}
         */
        getVanity: async ({ id, code }) => await client.get(
            `/${id}/vanity`,
            {
                auth: {
                    username: '',
                    password: code
                }
            }
        )
    };
};